<script>
import { useTheme } from 'vuetify';
import emitter from '@/plugins/mitt.js';
import AttachmentPreviewModal from '@/components/Modals/attachmentPreviewModal.vue';
import { mapGetters } from 'vuex';
import { createFileFromBase64 } from '@/utils/NewFunctions/utils.js';

export default {
  emits: ['fileSelected', 'fileError', 'fileProcessing'],
  name: 'FilePicker',
  components: { AttachmentPreviewModal },
  props: {
    selectedFile: String,
    disabled: Boolean,
  },
  data() {
    return {
      validFileTypes: ['application/pdf', 'image/jpeg', 'image/png'],
      file: null,
      fileError: false,
      fileErrorMessage: '',
      showAttachmentPreview: false,
      attachmentWidth: 800,
      attachmentHeight: 600,
      bytes: null,
    };
  },
  methods: {
    async handleFileChange(file) {
      if (file) {
        try {
          this.fileError = false;
          this.fileErrorMessage = '';
          if (
            !this.validFileTypes.includes(file.type) &&
            this.chosen_request == null
          ) {
            this.file = null;
            this.fileError = true;
            this.fileErrorMessage = 'File type not supported.';
            return;
          }

          const maxFileSize = 16 * 1024 * 1023; // 16MB
          if (file.size > maxFileSize) {
            this.file = null;
            this.fileError = true;
            this.fileErrorMessage = 'File size exceeds 16MB limit.';
            return;
          }
          this.$store.dispatch('setChosenAttachment', this.file);
          this.$emit('fileSelected');
        } catch (error) {
          console.error('Error handling file:', error);
          this.file = null;
          this.fileError = true;
          this.fileErrorMessage = 'File size exceeds 16MB limit.';
          this.$emit('fileError', error.message);
        }
      }
    },
    previewFile() {
      this.showAttachmentPreview = true;
    },
    updateDimensions({ width, height }) {
      this.attachmentWidth = width;
      this.attachmentHeight = height;
    },
    resetData() {
      this.file = null;
      this.fileError = false;
      this.fileErrorMessage = '';
      this.$emit('fileSelected', null);
    },
  },
  watch: {
    file(newFile) {
      if (newFile == null) {
        this.$store.dispatch('setChosenAttachment', null);
        this.$emit('fileSelected');
      } else {
        this.handleFileChange(newFile);
      }
    },
  },
  computed: {
    ...mapGetters(['chosen_request']),
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
  },
  mounted() {
    if (this.chosen_request?.attachment) {
      this.file = createFileFromBase64(this.chosen_request.attachment);
    }
    emitter.on('clearFile', () => {
      this.file = null;
    });
  },
};
</script>

<template>
  <v-file-input
    :disabled="disabled"
    v-model="file"
    :show-size="1000"
    :color="primaryColor1"
    label="Upload File"
    :prepend-icon="null"
    prepend-inner-icon="mdi-paperclip"
    counter
    accept=".pdf, .jpg, .png"
    :error="fileError"
    :error-messages="fileErrorMessage"
  >
    <template v-slot:selection="{ fileNames }">
      <template v-for="fileName in fileNames" :key="fileName">
        <v-chip class="me-2" :color="primaryColor1" size="small" label>
          {{ fileName }}
        </v-chip>
      </template>
    </template>

    <template v-slot:append-inner>
      <div @click.stop.prevent>
        <v-btn
          v-if="file && !disabled"
          icon
          variant="text"
          @click="previewFile"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </div>
    </template>
  </v-file-input>

  <v-dialog
    v-model="showAttachmentPreview"
    :width="attachmentWidth"
    :height="attachmentHeight"
  >
    <attachmentPreviewModal :attachment="file" @dimensions="updateDimensions" />
  </v-dialog>
</template>

<style scoped></style>
