<template>
  <div>
    <v-container fluid style="background-color: white">
      <h3 class="font-weight-medium">Bill Payment</h3>
      <v-form ref="add_account_type_form">
        <v-row>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-text-field v-model="description" label="Description">
            </v-text-field>

            <v-text-field
              v-maska="moneyOption"
              class="mt-25px"
              :rules="requiredRule"
              v-model="amount"
              label="Amount"
            >
            </v-text-field>

            <v-tab
              prepend-icon="mdi-magnify"
              color="button_color"
              @click="listOfAccountsModal = true"
              >select
            </v-tab>
            <v-text-field
              @keyup.backspace="clearSourceAccount"
              @keydown="handleKeyDown"
              v-model="sourceAccountName"
              label="Source Account"
              :rules="requiredRule"
            ></v-text-field>
            <v-form>
              <v-row class="mt-4">
                <v-container fluid>
                  <v-row>
                    <v-col cols="6">
                      <v-form ref="file_upload_form">
                        <v-file-input
                          v-model="selectedFiles"
                          label="Select File"
                          multiple
                          :accept="acceptedFileTypes"
                          :rules="multipleFileRequiredRule"
                        >
                        </v-file-input>
                      </v-form>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn color="button_color" class="mt-4">Upload </v-btn>
                    </v-col>
                  </v-row>
                  <v-list>
                    <v-list-item
                      v-for="(file, index) in selectedFiles"
                      :key="index"
                    >
                      <v-icon @click="remove(file)">mdi-delete</v-icon>
                      {{ file.name }}
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-toolbar-title class="ml-4"> all files:</v-toolbar-title>
                  <v-list class="mt-4">
                    <v-list-item v-for="(file, index) in files" :key="index">
                      <v-icon>mdi-download</v-icon>
                      {{ file.name }}
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" md="6" sm="12" xs="12">
            <label for="date-picker" style="color: black; margin-top: -8px"
              >Date</label
            >
            <VueDatePicker
              auto-apply="true"
              id="date-picker"
              v-model="date"
              :show-timepicker="false"
            ></VueDatePicker>

            <v-tab
              @click="transactionTypeListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              style="margin-top: 20px"
              >select
            </v-tab>
            <v-text-field
              label="Purpose of Payment"
              v-model="transactionTypeName"
              @keyup.backspace="clearTransactionType"
              @keydown="handleKeyDown"
              :rules="requiredRule"
            ></v-text-field>

            <v-tab
              @click="thirdPartyListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>

            <v-text-field
              label="Subledger"
              v-model="thirdPartName"
              @keyup.backspace="clearTrustee"
              @keydown="handleKeyDown"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <div class="d-flex top-20">
            <v-btn class="ml-4" color="button_color" @click="showReport = true">
              Bill Payment Summary
            </v-btn>
          </div>
        </v-row>
      </v-form>

      <v-dialog v-model="thirdPartyListModal" width="auto">
        <third_party_list_modal thirdPartyType="ALL"></third_party_list_modal>
      </v-dialog>

      <v-dialog v-model="listOfAccountsModal" width="auto">
        <list_of_accounts_modal_v01
          third-party-type="TRUSTEE"
        ></list_of_accounts_modal_v01>
      </v-dialog>

      <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
      ></Success_Modal>

      <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
      ></Error_Modal>

      <v-dialog v-model="transactionTypeListModal" width="auto">
        <Transaction_Type_List_Modal_V01></Transaction_Type_List_Modal_V01>
      </v-dialog>
      <v-dialog v-model="showReport" max-width="720">
        <v-card style="padding: 20px">
          <div class="border-1">
            <div class="grid-card-2">
              <p>Date:</p>
              <p class="m-l-35">{{ date }}</p>
              <p>Amount:</p>
              <p class="m-l-35">{{ amount }}</p>
              <p>Source Account:</p>
              <p class="m-l-35">{{ sourceAccountName }}</p>
              <p>Transaction Type:</p>
              <p class="m-l-35">{{ transactionTypeName }}</p>
              <p>Subledger:</p>
              <p class="m-l-35">{{ thirdPartName }}</p>
              <p>Description:</p>
              <p class="m-l-35">{{ description }}</p>
            </div>
            <v-row justify="end">
              <div class="d-flex top-20">
                <v-btn
                  color="button_color"
                  style="margin-right: 10px"
                  @click="addBillPayment"
                  >{{ $t('confirm') }}
                </v-btn>
                <v-btn color="button_color" @click="showReport = false"
                  >Cancel</v-btn
                >
              </div>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {
  extractNumber,
  handleKeyDown,
  unifiedResponseHandler,
  setPage,
} from '@/utils/helpers';
import { ACCEPTED_FILE_TYPES } from '@/config/constants';
import {
  multipleFileRequiredRule,
  numericRequiredRule,
  requiredRule,
} from '@/utils/rules';
import manual_transferService from '@/services/manual_transfer.service';
import emitter from '@/plugins/mitt';
import Transaction_Type_List_Modal_V01 from '../../Modals/Transaction_Type_List_Modal_V01.vue';
import list_of_accounts_modal_v01 from '../../Modals/List_Of_Accounts_Modal_V01.vue';
import third_party_list_modal from '../../Modals/Third_Party_List_Modal_V01.vue';
import { mapGetters } from 'vuex';
import { MoneyOption } from '@/utils/maska-options.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';

export default {
  name: 'bill_payment_v01',
  props: ['manualTransferType'],
  components: {
    Success_Modal,
    Error_Modal,
    Transaction_Type_List_Modal_V01,
    list_of_accounts_modal_v01,
    third_party_list_modal,
  },
  data() {
    return {
      formValid: false,
      selectedFiles: [],
      files: [],
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      multipleFileRequiredRule: multipleFileRequiredRule,
      error: false,
      success: false,
      message: null,
      transactionType: null,
      selectedType: null,
      selectedCurrency: null,
      selectedInternalType: null,
      selectedAccount: null,
      thirdParty: null,
      amount: null,
      selectedEventType: null,
      selectedCurrencyDir: null,
      transactionTypeConfigId: null,
      description: null,
      requiredRule: requiredRule,
      transactionTypeListModal: false,
      listOfAccountsModal: false,
      numericRequiredRule: numericRequiredRule,
      thirdPartyListModal: false,
      date: null,
      showReport: false,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearTransactionType() {
      this.transactionType = null;
    },
    clearSourceAccount() {
      this.selectedAccount = null;
    },
    clearTrustee() {
      this.thirdParty = null;
    },
    remove(file) {
      this.selectedFiles = this.selectedFiles.filter((item) => item !== file);
    },
    async addBillPayment() {
      let { valid } = await this.$refs.add_account_type_form.validate();
      if (valid) {
        let form = {
          accountId: this.sourceAccountId,
          transactionTypeId: this.transactionTypeId,
          thirdPartyId: this.thirdPartyId,
          amount: extractNumber(this.amount),
          description: this.description,
          currencyId: this.selectedAccount.currency.id,
          occurrenceDate: this.date,
        };
        console.log(this.selectedAccount);
        console.log('form', form);
        this.showReport = false;
        let response = unifiedResponseHandler(
          await manual_transferService.addManualTransfer(form)
        );

        this.message = response.message;
        if (response.success) {
          this.success = true;
        } else {
          this.error = true;
        }
      } else {
        this.error = true;
        this.message =
          'Please make sure to fill out all the required fields and complete the form before submitting it';
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
  },
  async mounted() {
    emitter.on('new_transaction_type', (value) => {
      this.transactionType = value;
      this.transactionTypeListModal = false;
    });

    emitter.on('chosenAccount', (value) => {
      this.selectedAccount = value;
      this.listOfAccountsModal = false;
    });

    emitter.on('new_third_party', (value) => {
      this.thirdParty = value;
      this.thirdPartyListModal = false;
    });
  },
  computed: {
    ...mapGetters([
      'chosen_transaction_type_config',
      'is_editing_transaction_type_config',
    ]),
    transactionTypeName() {
      if (this.transactionType) {
        return (
          this.transactionType.ledgerCode +
          ' ' +
          this.transactionType.description
        );
      } else {
        return null;
      }
    },
    transactionTypeId() {
      if (this.transactionType) {
        return this.transactionType.id;
      } else {
        return null;
      }
    },

    thirdPartName() {
      if (this.thirdParty) {
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },
    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
    sourceAccountName() {
      if (this.selectedAccount) {
        return this.selectedAccount.cofferName;
      } else {
        return null;
      }
    },
    sourceAccountId() {
      if (this.selectedAccount) {
        return this.selectedAccount.id;
      } else {
        return null;
      }
    },
    moneyOption() {
      // if (this.selectedCurrency) {
      //   const option = MoneyOption(this.selectedCurrency.symbol);
      //   return option;
      // } else {
      return MoneyOption(null);
      // }
    },
  },
  watch: {
    success(newValue) {
      if (!newValue) {
        setPage('bills_history');
      }
    },
  },
  beforeUnmount() {},
};
</script>
