<template>
  <Navbar_V01></Navbar_V01>
  <VLayout class="full-height">
    <Sidebar_V01 v-if="showSidebar"></Sidebar_V01>
    <v-main class="flex-grow" style="overflow-y: scroll; margin-bottom: 50px">
      <v-container fluid>
        <v-sheet color="blue-grey-lighten-4" elevation="6">
          <Sidebar_V01 v-if="!showSidebar"></Sidebar_V01>
        </v-sheet>
      </v-container>
      <Home_Main_V01
        v-if="page === 'home_main' || page === 'My_Notes'"
      ></Home_Main_V01>
      <My_Dashboard_V01 v-if="page === 'my_dashboard'"></My_Dashboard_V01>
      <Money_Transfer_Home_Page_V01
        v-if="page === 'money_transfer'"
      ></Money_Transfer_Home_Page_V01>
      <Report_Home_Page_V01 v-if="page === 'reports'"></Report_Home_Page_V01>
      <third-party-group-list
        v-if="page === 'third_party_groups'"
      ></third-party-group-list>
      <Past_Operation_Home_Page_V01
        v-if="page === 'past_operation'"
      ></Past_Operation_Home_Page_V01>
      <Commerce_Home_Page_V01
        v-if="page === 'commerce'"
      ></Commerce_Home_Page_V01>
      <Accounting_Home_Page_V01
        v-if="page === 'accounting'"
      ></Accounting_Home_Page_V01>
      <BankAndCash_Home_Page_V01
        v-if="page === 'bank_and_cash'"
      ></BankAndCash_Home_Page_V01>
      <New_User_V01 v-if="page === 'new_user'"></New_User_V01>
      <Users_List_V01 v-if="page === 'users'"></Users_List_V01>
      <User_Details_V01 v-if="page === 'user_details'"></User_Details_V01>
      <product-list v-if="page === 'product_database'"></product-list>
      <Paching_list_V01 v-if="page === 'paching_list'"></Paching_list_V01>
      <P_Crypto_Deal v-if="page === 'p_crypto_deal'"></P_Crypto_Deal>
      <New_individual_V01 v-if="page === 'new_individual'"></New_individual_V01>
      <New_Third_Party_V01
        v-if="page === 'new_third_party'"
      ></New_Third_Party_V01>
      <Third_Party_List_V01
        v-if="
          page === 'all_third_parties' ||
          page === 'customers' ||
          page === 'trustees' ||
          page === 'payers/payees' ||
          page === 'safeBox' ||
          page === 'Income_Center' ||
          page === 'Cost_Center'
        "
        :thirdPartyType="page"
      ></Third_Party_List_V01>
      <Currency_Database_V01
        v-if="page === 'currency_database'"
      ></Currency_Database_V01>
      <Country_Database_V01
        v-if="page === 'country_database'"
      ></Country_Database_V01>
      <individual_List_V01 v-if="page === 'contacts'"></individual_List_V01>
      <List_Of_Accounts_V01
        third-party-type="TRUSTEE"
        v-if="page === 'trustee_acc'"
      ></List_Of_Accounts_V01>
      <List_Of_Accounts_V01
        third-party-type="CUSTOMER"
        v-if="page === 'customer_acc'"
      ></List_Of_Accounts_V01>
      <List_Of_Accounts_V01
        third-party-type="SAFE_BOX"
        v-if="page === 'safe_box_acc'"
      ></List_Of_Accounts_V01>
      <List_Of_Accounts_V01
        v-if="page === 'payer_payee_acc'"
        :thirdPartyType="'SECONDARY_THIRDPARTY'"
      ></List_Of_Accounts_V01>
      <List_Of_Transactions_V01
        v-if="page === 'journals'"
      ></List_Of_Transactions_V01>
      <Money_Transfer_V01
        v-if="page === 'cash_receive'"
        requestType="CASH_RECEIVE"
      ></Money_Transfer_V01>
      <Money_Transfer_V01
        requestType="CASH_SEND"
        v-if="page === 'cash_send'"
      ></Money_Transfer_V01>
      <Money_Transfer_V01
        requestType="BANK_RECEIVE"
        v-if="page === 'bank_receive'"
      ></Money_Transfer_V01>
      <Money_Transfer_V01
        requestType="BANK_SEND"
        v-if="page === 'bank_send'"
      ></Money_Transfer_V01>
      <Internal_Transfer
        v-if="page === 'internal_trustee'"
        internalTransferType="TRUSTEE_TO_TRUSTEE"
      ></Internal_Transfer>
      <Internal_Transfer
        internalTransferType="CUSTOMER_TO_CUSTOMER"
        v-if="page === 'internal_customer'"
      ></Internal_Transfer>
      <P_Internal_Transfer_V01
        internalTransferType="TRUSTEE_TO_TRUSTEE"
        v-if="page === 'p_internal_trustee'"
      ></P_Internal_Transfer_V01>
      <P_Internal_Transfer_V01
        internalTransferType="CUSTOMER_TO_CUSTOMER"
        v-if="page === 'p_internal_customer'"
      ></P_Internal_Transfer_V01>
      <List_Of_General_Ledgers_V01
        v-if="page === 'chart_of_accounts'"
      ></List_Of_General_Ledgers_V01>
      <List_Of_Account_Types_V01
        v-if="page === 'account_types'"
      ></List_Of_Account_Types_V01>
      <New_Account_Type_V01
        v-if="page === 'new_account_type'"
      ></New_Account_Type_V01>
      <Transaction_Type_Config_List_V01
        v-if="page === 'transaction_type_configs'"
      >
      </Transaction_Type_Config_List_V01>
      <Transaction_Type_List_V01 v-if="page === 'transaction_types'">
      </Transaction_Type_List_V01>
      <All_Requests_V01 v-if="page === 'transfer_report'"></All_Requests_V01>
      <Exchange_Rate_V01 v-if="page === 'exchange_rate'"></Exchange_Rate_V01>
      <Internal_Transfer_List_V01 v-if="page === 'internal_report'">
      </Internal_Transfer_List_V01>
      <Crypto_List_V01 v-if="page === 'crypto_report'"></Crypto_List_V01>
      <SOA_v01 thirdPartyType="TRUSTEE" v-if="page === 'trustee_SOA'"></SOA_v01>
      <SOA_v01
        thirdPartyType="CUSTOMER"
        v-if="page === 'customer_SOA'"
      ></SOA_v01>
      <SOA_v01
        thirdPartyType="TRUSTEE"
        v-if="page === 'trustee_6203'"
      ></SOA_v01>
      <SOA_v01
        thirdPartyType="CUSTOMER"
        v-if="page === 'customer_4018'"
      ></SOA_v01>
      <SOA_v01 v-if="page === 'total_4018-6203'"></SOA_v01>
      <Bill_Payment_V01 v-if="page === 'bill_payment'"></Bill_Payment_V01>
      <Bills_Report_V01 v-if="page === 'bills_history'"></Bills_Report_V01>
      <third_party_entries
        v-if="page === 'customer_entries'"
        :thirdPartyType="'Customer'"
      ></third_party_entries>
      <third_party_entries
        v-if="page === 'payer_payee_entries'"
        :thirdPartyType="'Payer/Payee'"
      ></third_party_entries>
      <third_party_entries
        v-if="page === 'trustee_entries'"
        :thirdPartyType="'Trustee'"
      ></third_party_entries>
      <third_party_entries
        v-if="page === 'safe_box_entries'"
        :thirdPartyType="'Safebox'"
      ></third_party_entries>
      <P_Money_Transfer_V01
        v-if="page === 'p_cash_receive'"
        requestType="CASH_RECEIVE"
      ></P_Money_Transfer_V01>
      <P_Money_Transfer_V01
        v-if="page === 'p_cash_send'"
        requestType="CASH_SEND"
      ></P_Money_Transfer_V01>
      <P_Money_Transfer_V01
        v-if="page === 'p_bank_receive'"
        requestType="BANK_RECEIVE"
      ></P_Money_Transfer_V01>
      <P_Money_Transfer_V01
        requestType="BANK_SEND"
        v-if="page === 'p_bank_send'"
      ></P_Money_Transfer_V01>
      <Root_Analysis_V01 v-if="page === 'route_analysis'"></Root_Analysis_V01>
      <Free_Cash_Report_V01
        thirdPartyType="REPORT"
        v-if="page === 'currency_exposure'"
      ></Free_Cash_Report_V01>
      <Free_Cash_Report_V01
        thirdPartyType="CUSTOMER"
        v-if="page === 'customer_acc_sum'"
      ></Free_Cash_Report_V01>
      <Free_Cash_Report_V01
        thirdPartyType="TRUSTEE"
        v-if="page === 'trustee_acc_sum'"
      ></Free_Cash_Report_V01>
      <Journal_Entry v-if="page === 'journal_entry'"></Journal_Entry>
      <Journal_Entry_Report v-if="page === 'journal_entry_report'">
      </Journal_Entry_Report>
      <Manual_Transaction_New_V01
        v-if="page === 'manual_transaction'"
      ></Manual_Transaction_New_V01>
      <Manual_Report_V01 v-if="page === 'manual_report'"></Manual_Report_V01>
      <Company_Database_V01
        v-if="page === 'new_company_database'"
      ></Company_Database_V01>
      <Company_Report_V01
        v-if="page === 'company_database'"
      ></Company_Report_V01>
      <Proforma_Invoice_V01
        v-if="page === 'proforma_invoice'"
      ></Proforma_Invoice_V01>
      <Checkbooks_V01 v-if="page === 'checkbooks'"></Checkbooks_V01>
      <CryptoDeal_V01 v-if="page === 'crypto_deal'"></CryptoDeal_V01>
      <GroupDeal_V01
        v-if="page === 'group_deal'"
        page="GroupDeal"
      ></GroupDeal_V01>
      <GroupDeal_V01
        v-if="page === 'p_group_deal'"
        page="PastGroupDeal"
      ></GroupDeal_V01>
      <SafeBox_List_V01 v-if="page === 'safe_box_report'"></SafeBox_List_V01>
      <SafeBox_V01 v-if="page === 'safe_box'"></SafeBox_V01>
      <GroupDeal_Report_V01
        v-if="page === 'group_deal_report'"
      ></GroupDeal_Report_V01>
      <Meeting_v01 v-if="page === 'My_Meetings'"></Meeting_v01>
      <Reminder_V01 v-if="page === 'My_Reminders'"></Reminder_V01>
      <TaskGroup v-if="page === 'task_groups'"></TaskGroup>
      <Task_V01 v-if="page === 'My_Tasks'"></Task_V01>
    </v-main>
  </VLayout>
</template>
<script>
import Navbar_V01 from '@/components/Navbar_V01.vue';
import Sidebar_V01 from '@/components/Sidebar_V01.vue';
import Home_Main_V01 from '@/components/HomeManagement/home-page.vue';
import New_User_V01 from '@/components/HomeManagement/Setup/New_User_V01.vue';
import Users_List_V01 from '@/components/HomeManagement/Setup/Users_List_V01.vue';
import { mapGetters } from 'vuex';
import User_Details_V01 from '@/components/HomeManagement/Setup/User_Details_V01.vue';
import New_individual_V01 from '@/components/HomeManagement/Setup/new-individual.vue';
import individual_List_V01 from '@/components/HomeManagement/Setup/individual-list.vue';
import New_Third_Party_V01 from '@/components/HomeManagement/ThirdParty/new-third-party.vue';
import Third_Party_List_V01 from '@/components/HomeManagement/ThirdParty/third-party-list.vue';
import Currency_Database_V01 from '@/components/HomeManagement/Setup/Currency_Database_V01.vue';
import Country_Database_V01 from '@/components/HomeManagement/Setup/Country_Database_V01.vue';
import { authEmail } from '@/services/auth-header';
import List_Of_Accounts_V01 from '@/components/BankAndCash/List_Of_Accounts_V01.vue';
import List_Of_Transactions_V01 from '@/components/Accounting/List_Of_Transactions_V01.vue';
import List_Of_General_Ledgers_V01 from '@/components/Accounting/List_Of_General_Ledgers_V01.vue';
import List_Of_Account_Types_V01 from '@/components/HomeManagement/Configuration/account-types-list.vue';
import My_Dashboard_V01 from '@/components/HomeManagement/MyDashboard/my-dashboard.vue';
import New_Account_Type_V01 from '@/components/HomeManagement/Setup/New_Account_Type_V01.vue';
import Transaction_Type_Config_List_V01 from '@/components/HomeManagement/Configuration/transaction-type-config-list.vue';
import Transaction_Type_List_V01 from '@/components/HomeManagement/Configuration/transaction-type-list.vue';
import All_Requests_V01 from '@/components/MoneyTransfer/transfer-lists/All_Requests_V01.vue';
import Money_Transfer_V01 from '@/components/MoneyTransfer/transfer-forms/Money_Transfer_V01.vue';
import Exchange_Rate_V01 from '@/components/HomeManagement/Setup/Exchange_Rate_V01.vue';
import Internal_Transfer_List_V01 from '@/components/MoneyTransfer/transfer-lists/Internal_Transfer_List_V01.vue';
import Crypto_List_V01 from '@/components/MoneyTransfer/transfer-lists/Crypto_List_V01.vue';
import Internal_Transfer from '@/components/MoneyTransfer/transfer-forms/InternalTransfer/Internal_Transfer.vue';
import SOA_v01 from '@/components/Accounting/SOA_v01.vue';
import Bill_Payment_V01 from '@/components/MoneyTransfer/transfer-forms/Bill_Payment_V01.vue';
import Bills_Report_V01 from '@/components/Accounting/Bills_Report_V01.vue';
import third_party_entries from '@/components/BankAndCash/Entries.vue';
import Checkbooks_V01 from '@/components/BankAndCash/Checkbooks_V01.vue';
import P_Money_Transfer_V01 from '@/components/PastOperation/P_Money_Transfer_V01.vue';
import P_Internal_Transfer_V01 from '@/components/PastOperation/past-internal-transfer.vue';
import P_Crypto_Deal from '@/components/PastOperation/p-crypto-deal.vue';
import Root_Analysis_V01 from '@/components/Reports/Root_Analysis_V01.vue';
import Free_Cash_Report_V01 from '@/components/Reports/Free_Cash_Report_V01.vue';
import Journal_Entry_Report from '@/components/Accounting/journal-entry-report.vue';
import Journal_Entry from '@/components/Accounting/journal-entry.vue';
import Manual_Transaction_New_V01 from '@/components/Accounting/Manual_Transaction_New_V01.vue';
import Manual_Report_V01 from '@/components/Accounting/Manual_Report_V01.vue';
import Company_Database_V01 from '@/components/HomeManagement/Setup/Company_Database_V01.vue';
import Company_Report_V01 from '@/components/HomeManagement/Setup/Company_Report_V01.vue';
import Money_Transfer_Home_Page_V01 from '@/components/MoneyTransfer/money-transfer-page.vue';
import Report_Home_Page_V01 from '@/components/Reports/report-home-page.vue';
import Past_Operation_Home_Page_V01 from '@/components/PastOperation/past-operation-page.vue';
import Commerce_Home_Page_V01 from '@/components/Commerce/commerce-page.vue';
import BankAndCash_Home_Page_V01 from '@/components/BankAndCash/bank-and-cash-page.vue';
import Accounting_Home_Page_V01 from '@/components/Accounting/accounting-page.vue';
import Proforma_Invoice_V01 from '@/components/Commerce/Proforma_Invoice_V01.vue';
import Paching_list_V01 from '@/components/Commerce/Paching_list_V01.vue';
import CryptoDeal_V01 from '@/components/MoneyTransfer/transfer-forms/crypto-deal.vue';
import GroupDeal_V01 from '@/components/MoneyTransfer/transfer-forms/GroupDeal_V01.vue';
import SafeBox_V01 from '@/components/MoneyTransfer/transfer-forms/SafeBox_V01.vue';
import SafeBox_List_V01 from '@/components/MoneyTransfer/transfer-lists/SafeBox_List_V01.vue';
import GroupDeal_Report_V01 from '@/components/MoneyTransfer/transfer-lists/GroupDeal_Report_V01.vue';
import jwtDecode from 'jwt-decode';
import AuthService from '@/services/auth.service';
import ThirdPartyGroupList from '@/components/HomeManagement/ThirdParty/ThirdPartyGroup/third-party-group-list.vue';
import ProductList from '@/components/HomeManagement/Setup/Product/product-list.vue';
import Meeting_v01 from '@/components/HomeManagement/MyDashboard/meeting.vue';
import Reminder_V01 from '@/components/HomeManagement/MyDashboard/reminder.vue';
import Task_V01 from '@/components/HomeManagement/MyDashboard/task.vue';
import TaskGroup from '@/components/HomeManagement/Setup/task-group.vue';
import { unifiedResponseHandler } from '@/utils/helpers.js';
import userService from '@/services/user.service.js';

export default {
  name: 'dashboard_V01',
  components: {
    TaskGroup,
    Task_V01,
    Reminder_V01,
    Meeting_v01,
    ProductList,
    ThirdPartyGroupList,
    Navbar_V01,
    Sidebar_V01,
    Past_Operation_Home_Page_V01,
    Paching_list_V01,
    Commerce_Home_Page_V01,
    BankAndCash_Home_Page_V01,
    Accounting_Home_Page_V01,
    Home_Main_V01,
    New_User_V01,
    Users_List_V01,
    User_Details_V01,
    New_individual_V01,
    individual_List_V01,
    Third_Party_List_V01,
    Currency_Database_V01,
    Checkbooks_V01,
    Country_Database_V01,
    New_Third_Party_V01,
    CryptoDeal_V01,
    List_Of_Accounts_V01,
    List_Of_Transactions_V01,
    List_Of_General_Ledgers_V01,
    List_Of_Account_Types_V01,
    New_Account_Type_V01,
    My_Dashboard_V01,
    Transaction_Type_Config_List_V01,
    Transaction_Type_List_V01,
    All_Requests_V01,
    Money_Transfer_V01,
    Exchange_Rate_V01,
    Internal_Transfer,
    Internal_Transfer_List_V01,
    Crypto_List_V01,
    SOA_v01,
    Bill_Payment_V01,
    Bills_Report_V01,
    third_party_entries,
    P_Money_Transfer_V01,
    Money_Transfer_Home_Page_V01,
    Root_Analysis_V01,
    Free_Cash_Report_V01,
    Journal_Entry: Journal_Entry,
    Journal_Entry_Report: Journal_Entry_Report,
    Manual_Transaction_New_V01,
    Manual_Report_V01,
    Company_Database_V01,
    Company_Report_V01,
    P_Internal_Transfer_V01,
    Report_Home_Page_V01,
    Proforma_Invoice_V01,
    P_Crypto_Deal,
    GroupDeal_V01,
    SafeBox_List_V01,
    GroupDeal_Report_V01,
    SafeBox_V01,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      showFeaturesModal: false,
      newFeatures: [],
    };
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },
    setSessionTimer() {
      const userData = localStorage.getItem('auth/user');
      if (userData) {
        const user = JSON.parse(userData);
        const jwtToken = user.jwtToken;
        if (!jwtToken) {
          return;
        }
        const decodedToken = jwtDecode(jwtToken);
        const currentTime = Date.now() / 1000; // Convert to seconds
        const timeUntilExpiry = (decodedToken.exp - currentTime) * 1000;
        if (timeUntilExpiry < 0) {
          AuthService.logout();
          this.$router.push('login_v01');
        }
        console.log('Remaining time of this session: ', timeUntilExpiry);
        setTimeout(() => {
          AuthService.logout();
          this.$router.push('login_v01');
        }, timeUntilExpiry);
      }
    },
    getSystemTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    async getUserTimeZone() {
      let response = unifiedResponseHandler(
        await userService.getUserTimeZone(authEmail())
      );
      if (response.success) {
        return response.data;
      }
    },
    async updateUserTimeZone(timezone) {
      let response = unifiedResponseHandler(
        await userService.updateUserTimeZone(timezone)
      );
      if (response.success) {
        return response.data;
      }
    },
    async checkUserTimeZoneForUpdate() {
      const userTimeZone = await this.getUserTimeZone();
      const currentTimeZone = this.getSystemTimeZone();
      if (userTimeZone !== currentTimeZone) {
        let form = {
          userEmail: authEmail(),
          timeZone: currentTimeZone,
        };
        await this.updateUserTimeZone(form);
      }
    },
  },
  mounted() {
    let user = authEmail();
    if (!user) {
      this.$router.push('/login_v01');
    }
    this.checkUserTimeZoneForUpdate();
    this.setSessionTimer();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters([
      'page',
      'customer_management_options',
      'feeding_info_options',
      'user_management_options',
      'request_management_options',
      'section',
      'home_options',
      'individuals_options',
      'third_parties_options',
      'operations_options',
      'basic_info_options',
      'reports_options',
      'is_editing_user',
    ]),
    showSidebar() {
      return this.windowWidth > 1000;
    },
  },
};
</script>
<style>
.flex-grow {
  flex: 1;
}

.full-height {
  height: 100vh; /* Make the <v-layout> fill the viewport height */
}
</style>
