<template>
  <v-container fluid>
    <h3 class="mb-10 font-weight-medium">
      {{ formatRequestType(requestType) }}
    </h3>
    <br />

    <money-transfer-form
      :request-type="requestType"
      current-status="NOT INITIATED"
      :is-past-form="true"
      :is-editing-request="false"
      :chosen-request="null"
      @returnFormData="captureForms"
      @fileSelected="handleFileSelected"
    ></money-transfer-form>

    <v-row justify="end">
      <div class="d-flex top-20">
        <v-btn
          class="ml-4 mr-15"
          color="button_color"
          @click="showReport = true"
        >
          Review
        </v-btn>
      </div>
    </v-row>

    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
      :error="error"
      :message="message"
      @closeErrorModal="error = false"
    ></Error_Modal>

    <v-dialog v-model="showReport" max-width="720">
      <past-money-transfer-confirmation-modal
        :request-type="requestType"
        :register-form="this.registerForm"
        @close="showReport = false"
        @confirm="createPastMoneyTransferRequest()"
      ></past-money-transfer-confirmation-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import { unifiedResponseHandler } from '@/utils/helpers';
import money_transfer_requestService from '@/services/money_transfer_request.service';
import emitter from '@/plugins/mitt.js';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import moneyTransferForm from '@/components/MoneyTransfer/transfer-forms/moneyTransferForm.vue';
import PastMoneyTransferConfirmationModal from '@/components/MoneyTransfer/transfer-forms/pastMoneyTransferConfirmationModal.vue';
import { mapGetters } from 'vuex';
import attachmentService from '@/services/attachment.service.js';

export default {
  name: 'p_money_transfer_v01',
  components: {
    PastMoneyTransferConfirmationModal,
    moneyTransferForm,
    Success_Modal,
    Error_Modal,
  },
  props: ['requestType'],
  data() {
    return {
      error: false,
      bytes: null,
      success: false,
      message: null,
      tAccount: null,
      showReport: false,
      registerForm: null,
      reconcileForm: null,
      isAttachmentChanged: false,
    };
  },
  computed: {
    ...mapGetters(['chosen_attachment']),
  },
  methods: {
    captureForms({
      moneyTransferRegistrationForm,
      moneyTransferReconciliationForm,
    }) {
      this.registerForm = moneyTransferRegistrationForm;
      this.reconcileForm = moneyTransferReconciliationForm;
    },
    handleFileSelected() {
      this.bytes = this.chosen_attachment;
    },
    formatRequestType(requestType) {
      let transactionTypeDisplay = '';
      if (requestType === 'CASH_RECEIVE') {
        transactionTypeDisplay = 'Past Cash Receive';
      } else if (requestType === 'CASH_SEND') {
        transactionTypeDisplay = 'Past Cash Send';
      } else if (requestType === 'BANK_RECEIVE') {
        transactionTypeDisplay = 'Past Bank Receive';
      } else if (requestType === 'BANK_SEND') {
        transactionTypeDisplay = 'Past Bank Send';
      }
      return transactionTypeDisplay;
    },
    clearFormFields() {
      emitter.emit('clearFormFields');
    },
    async addAttachment(eventID) {
      if (!this.isAttachmentChanged) {
        return true;
      }
      let form = {
        targetID: eventID,
        attachmentTargetType: 'MONEY_TRANSFER',
        bytes: this.bytes,
      };
      let response = unifiedResponseHandler(
        await attachmentService.addOrUpdateAttachment(form)
      );
      if (response.success) {
        this.isAttachmentChanged = false;
      }
      return !!response.success;
    },
    async createPastMoneyTransferRequest() {
      this.showReport = false;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.createPastMoneyTransferRequest(
          this.registerForm
        )
      );
      if (response.success) {
        let attachmentSuccess = await this.addAttachment(response.data);
        if (!attachmentSuccess) {
          this.error = true;
          this.message = 'Error while adding attachment';
        } else {
          this.message = response.message;
          this.success = true;
          this.clearFormFields();
        }
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  watch: {
    bytes() {
      this.isAttachmentChanged = true;
    },
  },
};
</script>
