<template>
  <div>
    <v-container fluid>
      <Vue3MultiStepper
        v-if="currentStatus !== 'CANCELED'"
        v-model:step="step"
        :tabs="tabs"
        :primaryColor1="primaryColor1"
        :primaryColor2="primaryColor2"
        showNavigation="false"
        backText=""
        nextText=""
        doneText="Finish"
        :loading="loading"
      >
      </Vue3MultiStepper>

      <h3
        class="font-weight-medium"
        :style="{ top: '-50px', position: 'relative' }"
      >
        {{ requestTypeFormat(requestType) }}
      </h3>

      <money-transfer-form
        :request-type="requestType"
        :current-status="currentStatus"
        :is-past-form="false"
        :is-editing-request="this.is_editing_request"
        @returnFormData="captureForms"
        @fileSelected="handleFileSelected"
      ></money-transfer-form>

      <v-row
        class="ma-4"
        style="
          width: 95%;
          display: grid;
          grid-template: auto auto / auto auto;
          grid-gap: 10px;
          justify-content: end;
          align-items: end;
        "
      >
        <v-btn
          elevation="4"
          :disabled="
            !(
              currentStatus === 'NOT INITIATED' ||
              currentStatus === 'INITIATED' ||
              currentStatus === 'ACCOUNTS_UPDATED'
            ) || waitForResponse
          "
          width="150"
          height="70"
          color="button_color"
          @click="handleSaveOrRegisterOrReconciliationButton()"
        >
          {{ buttonText }}
        </v-btn>
        <v-btn
          elevation="4"
          width="150"
          height="70"
          color="button_color"
          @click="handleSaveAndNextButtonClick()"
          :disabled="currentStatus === 'ACCOUNTS_UPDATED' || waitForResponse"
        >
          Save And Next
        </v-btn>
        <v-btn
          elevation="4"
          color="button_color"
          width="150"
          height="70"
          @click="this.exit()"
          :disabled="waitForResponse"
        >
          Exit
        </v-btn>
        <v-btn
          :disabled="currentStatus === 'NOT INITIATED' || waitForResponse"
          elevation="4"
          width="150"
          height="70"
          @click="
            this.currentStatus === 'ACCOUNTS_UPDATED'
              ? (requestForDeletion = true)
              : this.currentStatus !== 'CANCELED'
                ? (requestForCancelRequest = true)
                : null
          "
          color="red"
          variant="tonal"
        >
          {{ cancelOrDeleteRequest }}
        </v-btn>
      </v-row>

      <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
      ></Success_Modal>
      <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
      ></Error_Modal>

      <v-dialog v-model="requestForCancelRequest">
        <accept-or-decline-with-info-modal
          :screenWidth="screenWidth"
          :color="primaryColor1"
          @decline="requestForCancelRequest = false"
          @accept="cancelRequest"
          title="Are you sure you want to cancel this request?"
          accept-text="Cancel Request"
          decline-text="Close"
        ></accept-or-decline-with-info-modal>
      </v-dialog>
      <v-dialog v-model="requestForApproveModal">
        <accept-or-decline-with-info-modal
          :screenWidth="screenWidth"
          :color="primaryColor1"
          @decline="cancelRequest"
          @accept="requestForApprove"
          title="Not enough balance"
          accept-text="Request For Approval"
          decline-text="Cancel Request"
        ></accept-or-decline-with-info-modal>
      </v-dialog>
      <v-dialog v-model="requestForDeletion">
        <accept-or-decline-with-info-modal
          :screenWidth="screenWidth"
          :color="primaryColor1"
          @decline="requestForDeletion = false"
          @accept="deleteRequest"
          title="Are you sure you want to delete this request?"
          accept-text="Delete Request"
          decline-text="Close"
        ></accept-or-decline-with-info-modal>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { unifiedResponseHandler, setPage, getIndex } from '@/utils/helpers';
import money_transfer_requestService from '@/services/money_transfer_request.service';
import { MONEY_TRANSFER_TABS } from '@/config/constants';
import { mapGetters } from 'vuex';
import { Vue3MultiStepper } from 'vue3-multi-stepper';
import 'jspdf-autotable';
import Success_Modal from '@/components/Utils/Modals/Success_Modal.vue';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { useTheme } from 'vuetify';
import AcceptOrDeclineWithInfoModal from '@/components/Utils/Modals/accept-or-decline-with-info-modal.vue';
import moneyTransferForm from '@/components/MoneyTransfer/transfer-forms/moneyTransferForm.vue';
import attachmentService from '@/services/attachment.service.js';

export default {
  name: 'money_transfer_v01',
  components: {
    AcceptOrDeclineWithInfoModal,
    moneyTransferForm,
    Error_Modal,
    Success_Modal,
    Vue3MultiStepper,
  },
  props: ['requestType'],
  data() {
    return {
      screenWidth: window.innerWidth,
      loading: false,
      bytes: null,
      tabs: MONEY_TRANSFER_TABS,
      error: false,
      success: false,
      message: null,
      step: 1,
      currentStatus: 'NOT INITIATED',
      requestId: null,
      requestForApproveModal: false,
      requestForCancelRequest: false,
      requestForDeletion: false,
      proceedAllowed: true,
      waitForResponse: false,
      registerForm: null,
      reconcileForm: null,
      isAttachmentChanged: false,
    };
  },
  methods: {
    captureForms({
      moneyTransferRegistrationForm,
      moneyTransferReconciliationForm,
    }) {
      this.registerForm = moneyTransferRegistrationForm;
      this.reconcileForm = moneyTransferReconciliationForm;
    },
    handleFileSelected() {
      this.bytes = this.chosen_attachment;
    },
    requestTypeFormat(requestType) {
      let transactionTypeDisplay = '';
      if (requestType === 'CASH_RECEIVE') {
        transactionTypeDisplay = 'Cash Receive';
      } else if (requestType === 'CASH_SEND') {
        transactionTypeDisplay = 'Cash Send';
      } else if (requestType === 'BANK_RECEIVE') {
        transactionTypeDisplay = 'Bank Receive';
      } else if (requestType === 'BANK_SEND') {
        transactionTypeDisplay = 'Bank Send';
      }
      return transactionTypeDisplay;
    },
    async handleSaveAndNextButtonClick() {
      switch (this.currentStatus) {
        case 'NOT INITIATED':
        case 'INITIATED':
          await this.addRequest(false, false);
          if (this.proceedAllowed) await this.generateTransaction();
          else this.proceedAllowed = true;
          break;
        case 'APPROVED':
          await this.generateTransaction();
          break;
        case 'TRANSACTION_REQUESTED':
          await this.trusteeConfirmed();
          break;
        case 'TRUSTEE_CONFIRMED':
          await this.customerConfirmed();
          this.exit();
          break;
        case 'requestForCancelRequest':
          if (
            this.currentStatus !== 'COMPLETED' &&
            this.currentStatus !== 'CANCELED'
          ) {
            this.requestForCancelRequest = true;
          }
          break;
        default:
          this.exit();
      }
    },
    async handleSaveOrRegisterOrReconciliationButton() {
      if (this.buttonText === 'Reconciliation') await this.doReconciliation();
      else await this.addRequest(true);
    },
    exit(withoutTimeOut = false) {
      if (withoutTimeOut) setPage('transfer report');
      setTimeout(() => {
        setPage('transfer report');
      }, 900);
    },
    async addAttachment(eventID) {
      if (!this.isAttachmentChanged) {
        return true;
      }
      const formData = new FormData();
      formData.append('targetID', eventID);
      formData.append('attachmentTargetType', 'MONEY_TRANSFER');
      formData.append('file', this.bytes);

      let response = unifiedResponseHandler(
        await attachmentService.addOrUpdateAttachment(formData)
      );
      if (response.success) {
        this.isAttachmentChanged = false;
      }
      return !!response.success;
    },
    async addRequest(exit = false, successDisplay = true) {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.createMoneyTransferRequest(
          this.registerForm
        )
      );
      this.message = response.message;
      if (response.success) {
        let attachmentSuccess = await this.addAttachment(response.data.id);
        if (!attachmentSuccess) {
          this.message = 'Error while adding attachment';
          this.error = true;
          this.proceedAllowed = false;
        } else {
          if (exit) {
            this.exit();
          }
          if (successDisplay) this.success = true;
          this.requestId = response.data.id;
          this.currentStatus = 'INITIATED';
          this.step = 2;
          this.$store.dispatch('setChosenRequest', response.data);
        }
      } else {
        this.error = true;
        this.proceedAllowed = false;
      }
      this.waitForResponse = false;
    },

    async generateTransaction(exit = false) {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.generateTransaction(this.requestId)
      );
      this.message = response.message;
      this.waitForResponse = false;
      if (response.success) {
        if (exit) {
          this.exit();
        }
        this.success = true;
        this.currentStatus = 'TRANSACTION_REQUESTED';
        this.step = 5;
      } else if (
        response.message === 'Customer balance is not enough' ||
        response.message === 'Trustee balance is not enough'
      ) {
        this.requestForApproveModal = true;
      } else {
        this.error = true;
      }
    },
    async requestForApprove() {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.changeRequestStatusToWaitingForApprove(
          this.requestId
        )
      );
      this.waitForResponse = false;
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.requestForApproveModal = false;
        this.currentStatus = 'WAITING_FOR_APPROVAL';
        this.step = 3;
      } else {
        this.error = true;
      }
    },
    async cancelRequest() {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.changeRequestStatusToCanceled(
          this.requestId
        )
      );
      this.waitForResponse = false;
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.requestForApproveModal = false;
        this.currentStatus = 'CANCELED';
        setTimeout(() => {
          setPage('transfer report');
        }, 1000);
      } else {
        this.error = true;
        this.message = 'You cannot cancel the request!';
      }
    },
    async deleteRequest() {
      this.waitForResponse = true;
      this.requestForDeletion = false;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.deleteById(this.requestId)
      );
      this.message = response.message;
      this.waitForResponse = false;
      if (response.success) {
        this.success = true;
        setTimeout(() => {
          setPage('transfer report');
        }, 1000);
      } else {
        this.error = true;
      }
    },

    async doReconciliation() {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.doReconciliation(this.reconcileForm)
      );
      this.waitForResponse = false;
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.exit();
      } else {
        this.error = true;
      }
    },

    async trusteeConfirmed() {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.trusteeConfirmed(this.requestId)
      );
      this.waitForResponse = false;
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.currentStatus = 'TRUSTEE_CONFIRMED';
        this.step = 6;
      } else {
        this.error = true;
      }
    },

    async customerConfirmed() {
      this.waitForResponse = true;
      let response = unifiedResponseHandler(
        await money_transfer_requestService.customerConfirmed(this.requestId)
      );
      this.waitForResponse = false;
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.currentStatus = 'COMPLETED';
        this.step = 7;
      } else {
        this.error = true;
      }
    },
  },
  watch: {
    bytes() {
      this.isAttachmentChanged = true;
    },
  },
  mounted() {
    if (this.is_editing_request) {
      this.requestId = this.chosen_request.id;
      this.currentStatus = this.chosen_request.status;
      this.step = getIndex(this.tabs, this.currentStatus, 0);
      this.bytes = this.chosen_request.attachment;
    }
  },
  computed: {
    cancelOrDeleteRequest() {
      if (this.currentStatus === 'ACCOUNTS_UPDATED') return 'Delete Request';
      else return 'Cancel Request';
    },
    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
    primaryColor2() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor2;
    },
    ...mapGetters([
      'chosen_request',
      'is_editing_request',
      'page',
      'chosen_attachment',
    ]),
    buttonText() {
      if (this.currentStatus === 'NOT INITIATED') return 'Register';
      else if (this.currentStatus === 'ACCOUNTS_UPDATED')
        return 'Reconciliation';
      else return 'Save';
    },
  },
  beforeUnmount() {
    this.$store.dispatch('setIsEditingRequest', false);
    this.$store.dispatch('setChosenRequest', null);
  },
};
</script>
<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-color: #b0b0b0;
}

.controls {
  display: none !important;
}
</style>
