<template>
  <v-container fluid>
    <div style="display: flex; justify-content: space-between">
      <h3 class="font-weight-medium">
        {{ thirdPartyTypeToDisplay }} {{ reportType }}
      </h3>
      <v-btn
        color="button_color"
        @click="QSOAModal = true"
        icon="mdi-help"
        class="ml-2"
      >
      </v-btn>
    </div>
    <v-form ref="generate_soa_form">
      <v-row>
        <v-col>
          <label for="date-picker" style="color: black">From:</label>
          <VueDatePicker
            style="margin-bottom: 20px"
            auto-apply="true"
            id="date-picker"
            v-model="startDate"
            model-type="yyyy-MM-dd"
            :show-timepicker="false"
          ></VueDatePicker>
          <v-tab
            @click="thirdPartListModal = true"
            prepend-icon="mdi-magnify"
            color="button_color"
            v-if="thirdPartyTypeToDisplay !== ''"
            >select
          </v-tab>
          <v-text-field
            v-if="thirdPartyTypeToDisplay !== ''"
            :label="'Select ' + thirdPartyTypeToDisplay"
            v-model="thirdPartyFullName"
            @keyup.backspace="clearThirdParty"
            @keydown="handleKeyDown"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col>
          <label for="date-picker" style="color: black">To:</label>
          <VueDatePicker
            style="margin-bottom: 20px"
            auto-apply="true"
            id="date-picker"
            v-model="endDate"
            model-type="yyyy-MM-dd"
            :show-timepicker="false"
          ></VueDatePicker>
          <br />
          <v-autocomplete
            v-if="reportType === 'SOA Report'"
            label="Select Currency"
            v-model="selectedCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn
          color="button_color"
          class="mr-4"
          @click="getSOAReport"
          :disabled="this.loadingReport === true"
          >generate report
        </v-btn>
      </v-row>
    </v-form>
    <v-dialog v-model="thirdPartListModal" width="auto">
      <third_party_list_modal
        :thirdPartyType="thirdPartyType"
      ></third_party_list_modal>
    </v-dialog>

    <v-dialog v-model="QSOAModal" width="auto">
      <Q_SOA_Modal_V01></Q_SOA_Modal_V01>
    </v-dialog>

    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
  </v-container>
</template>
<script>
import Q_SOA_Modal_V01 from '@/components/ModalQ/Q_SOA_Modal_V01.vue';
import transactionService from '@/services/transaction.service';
import {
  unifiedResponseHandler,
  formatCurrencyInfo,
  handleKeyDown,
} from '@/utils/helpers';
import third_party_list_modal from '../Modals/Third_Party_List_Modal_V01.vue';
import emitter from '@/plugins/mitt';
import currencyService from '@/services/currency.service';
import { requiredRule } from '@/utils/rules';
import Error_Modal from '@/components/Utils/Modals/Error_Modal.vue';
import { formatString } from '@/utils/NewFunctions/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'soa_v01',
  props: ['thirdPartyType'],
  components: {
    Error_Modal,
    third_party_list_modal,
    Q_SOA_Modal_V01,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      currencies: [],
      thirdPartListModal: false,
      newThirdPartyModal: false,
      QSOAModal: false,
      error: false,
      loadingReport: false,
      message: null,
      thirdParty: null,
      selectedCurrency: null,
      requiredRule: requiredRule,
    };
  },
  methods: {
    handleKeyDown,
    async getSOAReport() {
      const { valid } = await this.$refs.generate_soa_form.validate();
      if (valid) {
        this.loadingReport = true;
        let currencyId = null;
        if (this.selectedCurrency) currencyId = this.selectedCurrency.id;
        if (this.reportType === 'SOA Report')
          await transactionService.getSOAReport(
            this.startDate,
            this.endDate,
            currencyId,
            this.thirdPartyId
          );
        else
          await transactionService.getCommissionReport(
            this.startDate,
            this.endDate,
            this.thirdPartyId,
            this.reportType
          );
      }
      this.loadingReport = false;
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(
        await currencyService.getCurrencies()
      );
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
  },
  async mounted() {
    let endDate = new Date();
    let startDate = new Date(2020, 0, 1);
    const formattedEndDate = `${endDate.getFullYear()}-${(
      endDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${(endDate.getDate() + 1)
      .toString()
      .padStart(2, '0')}`;

    const formattedStartDate = `${startDate.getFullYear()}-${(
      startDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;

    this.endDate = formattedEndDate;
    this.startDate = formattedStartDate;
    emitter.on('new_third_party', (value) => {
      this.thirdParty = value;
      this.thirdPartListModal = false;
      this.newThirdPartyModal = false;
    });
    await this.getCurrencies();
  },

  computed: {
    ...mapGetters(['page']),
    thirdPartyFullName() {
      if (this.thirdParty) {
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },
    thirdPartyTypeToDisplay() {
      if (this.thirdPartyType) return formatString(this.thirdPartyType);
      return '';
    },

    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
    reportType() {
      let pageStringVal = this.page;
      if (pageStringVal.toLowerCase().includes('soa')) return 'SOA Report';
      else if (pageStringVal.toLowerCase().includes('total'))
        return 'Total Report 4018-6203';
      else if (pageStringVal.includes('6203')) return '6203';
      else return '4018';
    },
  },
};
</script>
